import React from 'react';
import {
  AccessibilityAuditComponentResultsSection,
  ComponentPreview,
  DontDo,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../../components';
import pageHeroData from '../../../../data/pages/components.yml';
import floatingActionPreviewData from '../../../../data/previews/floating-action.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sizeSelect: 'medium',
    };
  }

  handleSizeChange = () => {
    this.setState({
      sizeSelect: this.state.sizeSelect === 'medium' ? 'large' : 'medium',
    });
  };
  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Floating Action"
        designCode
        isMobileOnly
        subnav="components">
        <PageHero
          heroData={pageHeroData}
          tierTwo="Buttons"
          tierThree="Floating Action"
        />

        <PageNavigation
          links={['Size', 'Position', 'Accessibility', 'Platform']}
        />

        <Section title="Size">
          <Paragraph>
            The floating action button (FAB) has two sizes, medium and large.
          </Paragraph>
          <ComponentPreview
            name="PatternsContainer"
            hideEnvironmentToggle
            previewData={floatingActionPreviewData.size}
            onChange={this.handleSizeChange}>
            {this.state.sizeSelect === 'medium' && (
              <img
                width="470"
                src={require('../../../../imgs/previews/preview-fab-medium.jpg')}
              />
            )}
            {this.state.sizeSelect === 'large' && (
              <img
                src={require('../../../../imgs/previews/preview-fab-large.jpg')}
              />
            )}
          </ComponentPreview>
        </Section>

        <Section title="Position">
          <List>
            <li>
              <strong>Location:</strong> The floating action button appears at
              the bottom right of the interface in which the action takes place.
            </li>
            <li>
              <strong>Elevation:</strong> Consistent with our{' '}
              <Link href="/guidelines/layering/elevation/design#Stack%20Order">
                stack order
              </Link>
              , the FAB has a level 2 elevation.
            </li>
          </List>
          <SectionSubhead>Icon-Only</SectionSubhead>
          <Paragraph>
            This button is always icon-only, and should never have a label used
            in its place.
          </Paragraph>
          <DontDo
            dontText="use a label to explain an action."
            doText="use icons for adding or sharing."
            imgFilename="floating-action-button-icon-only"
          />

          <SectionSubhead>Actions</SectionSubhead>
          <Paragraph>
            The floating action button (FAB) should only initiate an action.
            Never use it to complete a workflow.
          </Paragraph>
          <DontDo
            dontText="use the button to modify or save content."
            doText="use it to initiate something constructive."
            imgFilename="floating-action-button-action-modify"
          />
          <Paragraph>
            Avoid using the FAB for bulk or destructive actions and instead
            place them elsewhere to avoid confusion.
          </Paragraph>
          <DontDo
            dontText="delete or remove content."
            doText="place secondary actions in other areas of the interface."
            imgFilename="floating-action-button-action-secondary"
          />
          <Paragraph>
            Important, primary actions make use of our action color, which
            demands a lot of attention. It’s best to limit its use to just one.
          </Paragraph>
          <DontDo
            dontText="use for multiple actions in an interface."
            doText="reserve for the single, most important action."
            imgFilename="floating-action-button-one"
          />
          <SectionSubhead>Position</SectionSubhead>
          <Paragraph>
            The floating action button should remain fixed and not be placed
            haphazardly.
          </Paragraph>
          <DontDo
            dontText="place the button overtop meaningful content."
            doText="keep its placement consistent to the bottom right."
            imgFilename="floating-action-button-position"
          />
          <SectionSubhead>Mobile-Only</SectionSubhead>
          <Paragraph>
            As exciting as this button may be, avoid using it for anything other
            than mobile applications.
          </Paragraph>
          <DontDo
            dontText="use the floating action button for web-only workflows."
            doText="use it for Apple and Android devices."
            imgFilename="floating-action-button-mobile-only"
          />
        </Section>

        <AccessibilityAuditComponentResultsSection componentName="Floating Action Button" />

        <Section title="Platform">
          <PlatformTable
            platforms={{
              web: (
                <List>
                  <li>
                    Follow Uniform’s{' '}
                    <Link href="/components/buttons/button/design/">
                      button guidelines
                    </Link>
                    .
                  </li>
                </List>
              ),
              apple: (
                <List>
                  <li>
                    Stick to the guidelines above in addition to the{' '}
                    <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/bars/navigation-bars/">
                      navigation bar
                    </Link>{' '}
                    guidelines for text title button controls.
                  </li>
                </List>
              ),
            }}
          />
        </Section>
      </PageWithSubNav>
    );
  }
}
